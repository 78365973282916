<template>
  <div class="detailBox" v-loading="tableLoading" element-loading-text="拼命加载中...">
    <!-- 预约申请单信息 -->
    <div class="memberSubmitBox">
      <el-card>
        <div class="title">预约申请单信息</div>
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">申请单单号：</div>
              <div>{{ detailObj.appointmentCode }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">提交时间：</div>
              <div div>{{ detailObj.creationTime }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">姓名：</div>
              <div>{{ detailObj.memberName }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">会员手机号：</div>
              <div>{{ detailObj.memberPhone }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">会员ID：</div>
              <div div>{{ detailObj.memberId }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">申请单状态：</div>
              <div v-if="detailObj.status == 0">待确认</div>
              <div v-if="detailObj.status == 1">已确认</div>
              <div v-if="detailObj.status == 2">已取消</div>
            </div>
          </el-col>
        </el-row>
        <!-- 明细 -->
        <el-row class="mt20">
          <el-col :span="24" class="flex">
            <div class="label">明细：</div>
            <div class="w-full">
              <el-row class="rowTitle">
                <el-col :span="4">门店名称</el-col>
                <el-col :span="2">员工</el-col>
                <el-col :span="4">项目</el-col>
                <el-col :span="4">联系手机号</el-col>
                <el-col :span="3">日期</el-col>
                <el-col :span="3">时间</el-col>
              </el-row>
              <el-row class="rowList">
                <el-col :span="4">{{ detailObj.shopName }}</el-col>
                <el-col :span="2">{{ detailObj.serveStaffName }}</el-col>
                <el-col :span="4">{{ detailObj.serveItemNames }}</el-col>
                <el-col :span="4">{{ detailObj.linkMobile }}</el-col>
                <el-col :span="3">{{ detailObj.serveDate }}</el-col>
                <el-col :span="3">{{ detailObj.serveStartTime }}-{{ detailObj.serveEndTime }}</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 预约单信息 -->
    <div class="memberSubmitBox mt20">
      <el-card>
        <div class="title">预约单信息</div>
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">预约单单号：</div>
              <div>{{ detailObj.appointmentId || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">创建时间：</div>
              <div>{{ detailObj.smCreationTime }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">预约单状态：</div>
              <div>
                <span v-if="detailObj.smServeStatus == 0">预约未到店</span>
                <span v-if="detailObj.smServeStatus == 1">已到店未服务</span>
                <span v-if="detailObj.smServeStatus == 2">服务中</span>
                <span v-if="detailObj.smServeStatus == 3">服务结束</span>
                <span v-if="detailObj.smServeStatus == 4">取消</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 明细 -->
        <el-row class="mt20">
          <el-col :span="24" class="flex">
            <div class="label">明细：</div>
            <div class="w-full">
              <el-row class="rowTitle">
                <el-col :span="4">门店名称</el-col>
                <el-col :span="4">员工</el-col>
                <el-col :span="2">项目</el-col>
                <el-col :span="2">联系手机号</el-col>
                <el-col :span="3">日期</el-col>
                <el-col :span="3">时间</el-col>
              </el-row>
              <el-row class="rowList">
                <el-col :span="4">{{ detailObj.shopName }}</el-col>
                <el-col :span="4">{{ detailObj.smServeStaffName }}</el-col>
                <el-col :span="2">{{ detailObj.smServeItemNames }}</el-col>
                <el-col :span="2">{{ detailObj.smLinkMobile }}</el-col>
                <el-col :span="3">{{ detailObj.smServeDate }}</el-col>
                <el-col :span="3">{{ detailObj.smServeStartTime }}-{{ detailObj.smServeEndTime }}</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <!-- 返回 -->
        <div class="buttonBox mt40 flex justify-center">
          <el-button @click="backFun">返回</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script setup name="CardDetail">
  import { ref, reactive, onMounted, computed } from 'vue'
  import { ElMessage } from "element-plus"
  import { appointmentDetail } from "@/http/returnCard"
  import { useRoute, useRouter } from 'vue-router'
  const route = useRoute()
  const router = useRouter()

  let returnId = route.query.id
  let returnType = route.query.type
  onMounted(() => {
    getDetail(returnId)
  })
  // 获取详情
  let tableLoading = ref(false)
  let detailObj = ref({})
  function getDetail(id) {
    let params = { id: id }
    tableLoading.value = true
    appointmentDetail(params).then(res => {
      tableLoading.value = false
      if (res.code == 200) {
        detailObj.value = res.data
      } else ElMessage.error({
        message: res.message,
        duration: 2000
      })
    }).catch(() => {
      tableLoading.value = false
    })
  }
  // 通过/取消/返回 退卡
  function backFun() {
    router.push('/Appointment')
  }
</script>

<style lang="scss" scoped>
.detailBox {
  position: relative;
  .textDel {
    text-decoration: line-through !important;
  }
  .statusTag {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .memberSubmitBox {
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .items {
      margin-bottom: 20px;
    }
    .item {
      margin-right: 10px;
      font-size: 14px;
      line-height: 26px;
    }
    .label {
      width: 150px;
      text-align: right;
      flex: 0 0 auto;
      font-size: 14px;
    }
    .rowTitle {
      border: 1px solid #ebeef5;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      background: rgb(242, 242, 242);
    }
    .rowList {
      border: 1px solid #ebeef5;
      border-top: none;
      padding: 10px 20px;
      font-size: 14px;
      color: #333;
    }
  }
}
</style>